import { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';

import UploadIcon from 'assets/images/svg/upload.svg';
import UploadResponseMessage from './UploadResponseMessage';
import { DragAndDropInputProps, StatefulUploadFile } from './interface';
import SelectedFileItem from './SelectedFileItem';
import './style.css';
import { useScrollContext } from 'contexts';

const DragAndDropFileInput = ({
  files,
  name,
  description,
  maxCount = 10,
  disabled,
  setFiles,
  handleUpload,
  multiple,
  accept,
  prepareForUpload,
  setShowedMessage,
  showedMessage,
  maxFileSizeMb = 10
}: DragAndDropInputProps) => {
  const { registerRef, scrollTo } = useScrollContext();

  const [shouldStartUpload, setShouldStartUpload] = useState<boolean>(false);

  const startUpload = () => {
    setShouldStartUpload(true);
  };

  const beforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    const fileSizeMb = (file.size || 0) / 1024 / 1024;

    if (fileSizeMb > maxFileSizeMb) {
      setShowedMessage({
        message: `Die Datei muss kleiner sein als ${maxFileSizeMb}MB!`,
        status: 'error'
      });
    } else if (files.length + fileList.length > maxCount) {
      setShowedMessage({
        message: `Die maximale Anzahl an Dateien beträgt ${maxCount}!`,
        status: 'error'
      });
    } else {
      setShowedMessage(null);
      const newFile = file as StatefulUploadFile;
      newFile.state = 'idle';
      setFiles((prev: StatefulUploadFile[]) =>
        maxCount === 1 ? [file] : [...prev, newFile]
      );
    }
    return false;
  };

  const handleRemove = (file: StatefulUploadFile) => {
    const newFileList = files.filter((cur) => cur.uid !== file.uid);
    setFiles(newFileList);
  };

  useEffect(() => {
    if (shouldStartUpload && !files.find((file) => file.state === 'loading')) {
      const pendingFile = files.find(
        (file) => file.state === 'idle' || file.state === 'forRetry'
      );
      if (pendingFile) handleUpload(pendingFile);
      else {
        setShouldStartUpload(false);
      }
    } else if (!shouldStartUpload) {
      const failedFiles = files.filter((file) => file.state === 'failed');
      const idleFailed = files.map((file) => {
        if (file.state === 'failed') {
          const newFile = file as StatefulUploadFile;
          newFile.state = 'forRetry';
          return newFile;
        }
        return file;
      });
      if (failedFiles.length > 0) setFiles([...idleFailed]);
    }
  }, [shouldStartUpload, files]);

  useEffect(() => {
    if (shouldStartUpload) {
      prepareForUpload(
        files.filter(
          (file) => file.state === 'idle' || file.state === 'forRetry'
        ).length
      );
    }
  }, [shouldStartUpload]);

  useEffect(() => {
    if (files.length === 0) {
      setShowedMessage(null);
    }
  }, [files]);

  useEffect(() => {
    scrollTo('upload-message', 0);
  }, [showedMessage]);

  return (
    <div className={'drag-and-drop-input'}>
      <Dragger
        fileList={files}
        name={name}
        maxCount={maxCount}
        multiple={multiple}
        disabled={disabled}
        beforeUpload={beforeUpload}
        accept={accept}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <img src={UploadIcon} alt="upload_icon" />
        </p>
        <p className="drag-and-drop-input-main-text">
          <u> Wählen Sie eine Datei</u> ({maxCount} Dateien max) oder ziehen Sie
          es in diesen Bereich
        </p>
        <p className="drag-and-drop-input-description">{description}</p>
      </Dragger>

      {showedMessage && (
        <UploadResponseMessage
          message={showedMessage.message}
          variant={showedMessage.status}
        />
      )}

      {files.map((file) => (
        <SelectedFileItem
          key={file.uid}
          file={file}
          handleRemove={() => handleRemove(file)}
        />
      ))}
      {files.length >= 1 && (
        <Row justify="end">
          <Button
            ref={(el) => registerRef('upload-message', el)}
            className="drag-and-drop-input-upload-button"
            onClick={startUpload}
          >
            <span className="drag-and-drop-input-upload-button-icon">
              <img src={UploadIcon} alt="upload_icon" />
              <p className="drag-and-drop-input-upload-button-text">
                Bestätigen Sie das Hochladen
              </p>
            </span>
          </Button>
        </Row>
      )}
    </div>
  );
};

export default DragAndDropFileInput;
