import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL } from '../../api-config';
import {
  ARGenerateSessionRequestData,
  ARGenerateSessionResponseData,
  ARSessionData,
  SessionValidityRequestData
} from 'types/bundle';

interface InitialState {
  data: ARSessionData | null;
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  generateSessionData: ARGenerateSessionResponseData | null;
  generateSessionError: string | null;
  generateSessionStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: InitialState = {
  data: null,
  status: 'idle',
  error: null,
  generateSessionData: null,
  generateSessionStatus: 'idle',
  generateSessionError: null
};

export const checkSessionValidityInAR = createAsyncThunk(
  'session/checkSessionValidityInAR',
  async (sessionValidityData: SessionValidityRequestData) => {
    const response = await axios.post(
      `${API_URL}/orders/sessions`,
      sessionValidityData
    );
    return response.data;
  }
);

export const generateNewSessionId = createAsyncThunk<
  ARGenerateSessionResponseData,
  ARGenerateSessionRequestData,
  { rejectValue: string }
>(
  'orders/sessions/generate',
  async (
    generateSessionData: ARGenerateSessionRequestData,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<ARGenerateSessionResponseData>(
        `${API_URL}/orders/sessions/generate`,
        generateSessionData
      );
      return response.data;
    } catch {
      return rejectWithValue(
        'Generate new sessionId failed. Please try again.'
      );
    }
  }
);

const sessionSlice = createSlice({
  name: 'arSession',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkSessionValidityInAR.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        checkSessionValidityInAR.fulfilled,
        (state, action: PayloadAction<ARSessionData>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(checkSessionValidityInAR.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(generateNewSessionId.pending, (state) => {
        state.generateSessionStatus = 'loading';
        state.generateSessionError = null;
      })
      .addCase(
        generateNewSessionId.fulfilled,
        (state, action: PayloadAction<ARGenerateSessionResponseData>) => {
          state.generateSessionStatus = 'succeeded';
          state.generateSessionError = null;
          state.generateSessionData = action.payload;
        }
      )
      .addCase(generateNewSessionId.rejected, (state, action) => {
        state.generateSessionStatus = 'failed';
        state.generateSessionError =
          action.payload ?? 'An unexpected error occurred.';
      });
  }
});

export const selectedIsValidSessionId = (state: { arSession: InitialState }) =>
  state.arSession?.data?.is_valid || false;

export default sessionSlice.reducer;
