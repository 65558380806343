import * as Yup from 'yup';

import { UserDetails as PersonalDetails } from './PersonalDetails/interface';
import { UserDetails as ShippingUserDetails } from './ShippingDetails/interface';
import { UserDetails as InvoiceUserDetails } from './InvoiceDetails/interface';
import { UserDetails as PropertyOwnerDetails } from './PropertyOwner/interface';
import { UserDetails as ContactPersonDetails } from './ContactPerson/interface';
import { InstallationAddressDetails } from './InstallationAddress/interface';
import { TITLE_NOT_SELECTED } from 'constants/Constants';

export interface SepaDetails {
  account_owner_title?: string;
  account_owner_name?: string;
  account_owner_last_name?: string;
  account_owner_company?: string;
}

export const REQUIRED_MESSAGE = 'Erforderlich';
const INVALID_EMAIL_MESSAGE = 'Ungültige E-Mail';
const POSTCODE_INVALID_MESSAGE = 'Muss genau 5 Ziffern lang sein';
const HOUSE_NUMBER_INVALID_MESSAGE = 'Nur Ziffern erlaubt';
const PHONE_NUMBER_INVALID_MESSAGE =
  'Erstes Zeichen muss 0 oder + sein und darf nur Ziffern enthalten (keine Buchstaben, Leerzeichen oder Sonderzeichen).';
export const REQUIRED_EMAIL_OR_NUMBER =
  'Bitte geben Sie die "E-Mail" oder die "Mobiltelefonnummer für Nachfragen" ein.';
const RESIDENTAL_UNTIS_COUNT_INVALID_MESSAGE =
  'Es sind nur positive Zahlen zulässig';

const userDetailsSchema: Yup.Schema<PersonalDetails> = Yup.object().shape({
  isEmailRequired: Yup.boolean(),
  customer_group_firm: Yup.boolean(),
  company: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const { customer_group_firm } = this.parent;

      if (!customer_group_firm) {
        return true;
      }

      if (!value) {
        return false;
      }

      return true;
    }
  ),
  title: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const { first_name, last_name, customer_group_firm } = this.parent;

      if (customer_group_firm) {
        if (
          (value === TITLE_NOT_SELECTED || !value) &&
          (first_name || last_name)
        ) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }

        if (value === TITLE_NOT_SELECTED) {
          return false;
        }
      }

      return true;
    }
  ),
  first_name: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const { title, last_name, customer_group_firm } = this.parent;

      if (customer_group_firm) {
        if (!value && ((title && title !== TITLE_NOT_SELECTED) || last_name)) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }
      }

      return true;
    }
  ),
  last_name: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const { title, first_name, customer_group_firm } = this.parent;

      if (customer_group_firm) {
        if (!value && ((title && title !== TITLE_NOT_SELECTED) || first_name)) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }
      }

      return true;
    }
  ),
  email: Yup.string().when('isEmailRequired', {
    is: true,
    then: () =>
      Yup.string().email(INVALID_EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
    otherwise: () => Yup.string()
  }),
  birth_date: Yup.string().test(
    'birthDateOptionalForCompanies',
    REQUIRED_MESSAGE,
    function (value) {
      const { customer_group_firm } = this.parent;
      if (customer_group_firm) {
        return true;
      }

      if (!value) {
        return false;
      }
      return true;
    }
  ),
  phone_number: Yup.string()
    .matches(/^(?:\+\d+|0\d+)$/, PHONE_NUMBER_INVALID_MESSAGE)
    .required(REQUIRED_MESSAGE)
});

const installationAddressSchema: Yup.Schema<InstallationAddressDetails> =
  Yup.object().shape({
    street: Yup.string().required(REQUIRED_MESSAGE),
    house_number: Yup.string()
      .matches(/^\d+$/, HOUSE_NUMBER_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    house_suffix: Yup.string(),
    post_code: Yup.string()
      .matches(/^[0-9]{5}$/, POSTCODE_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    location: Yup.string().required(REQUIRED_MESSAGE)
  });

const sepaDetailsSchema: Yup.Schema<SepaDetails> = Yup.object().shape({
  account_owner_company: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const {
        account_owner_title,
        account_owner_name,
        account_owner_last_name
      } = this.parent;

      if (
        !value &&
        !(account_owner_title && account_owner_name && account_owner_last_name)
      ) {
        return false;
      }

      return true;
    }
  ),
  account_owner_title: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const {
        account_owner_company,
        customer_group_firm,
        account_owner_name,
        account_owner_last_name
      } = this.parent;

      if (customer_group_firm) {
        if (!value && !account_owner_company) {
          return false;
        }

        if (value === TITLE_NOT_SELECTED && !account_owner_company) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }

        if (value === TITLE_NOT_SELECTED) {
          return false;
        }
      }

      if (
        (value === TITLE_NOT_SELECTED || !value) &&
        (account_owner_name || account_owner_last_name)
      ) {
        return false;
      }

      return true;
    }
  ),
  account_owner_name: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const {
        account_owner_company,
        customer_group_firm,
        account_owner_title,
        account_owner_last_name
      } = this.parent;

      if (customer_group_firm) {
        if (!value && !account_owner_company) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }
      }

      if (
        !value &&
        ((account_owner_title && account_owner_title !== TITLE_NOT_SELECTED) ||
          account_owner_last_name)
      ) {
        return false;
      }

      return true;
    }
  ),
  account_owner_last_name: Yup.string().test(
    'companyOrTitleNameLastName',
    REQUIRED_MESSAGE,
    function (value) {
      const {
        account_owner_company,
        customer_group_firm,
        account_owner_title,
        account_owner_name
      } = this.parent;

      if (customer_group_firm) {
        if (!value && !account_owner_company) {
          return false;
        }
      } else {
        if (!value) {
          return false;
        }
      }

      if (
        !value &&
        ((account_owner_title && account_owner_title !== TITLE_NOT_SELECTED) ||
          account_owner_name)
      ) {
        return false;
      }

      return true;
    }
  )
});

const shippingDetailsSchema: Yup.Schema<ShippingUserDetails> =
  Yup.object().shape({
    customer_group_firm: Yup.boolean(),
    company: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, title, first_name, last_name } =
          this.parent;

        if (!customer_group_firm) {
          return true;
        }

        if (!value && !(title && first_name && last_name)) {
          return false;
        }

        return true;
      }
    ),
    title: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, first_name, last_name } =
          this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }

          if (value === TITLE_NOT_SELECTED && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }

          if (value === TITLE_NOT_SELECTED) {
            return false;
          }
        }

        if (
          (value === TITLE_NOT_SELECTED || !value) &&
          (first_name || last_name)
        ) {
          return false;
        }

        return true;
      }
    ),
    first_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, title, last_name } = this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        if (!value && ((title && title !== TITLE_NOT_SELECTED) || last_name)) {
          return false;
        }

        return true;
      }
    ),
    last_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, title, first_name } = this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        if (!value && ((title && title !== TITLE_NOT_SELECTED) || first_name)) {
          return false;
        }

        return true;
      }
    ),
    street: Yup.string().required(REQUIRED_MESSAGE),
    house_number: Yup.string()
      .matches(/^\d+$/, HOUSE_NUMBER_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    house_suffix: Yup.string(),
    post_code: Yup.string()
      .matches(/^[0-9]{5}$/, POSTCODE_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    location: Yup.string().required(REQUIRED_MESSAGE)
  });

const invoiceDetailsSchema: Yup.Schema<InvoiceUserDetails> = Yup.object().shape(
  {
    isEmailRequired: Yup.boolean(),
    customer_group_firm: Yup.boolean(),
    company: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm } = this.parent;
        if (!customer_group_firm) {
          return true;
        }

        if (!value) {
          return false;
        }
        return true;
      }
    ),
    title: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, first_name, last_name } = this.parent;

        if (customer_group_firm) {
          if (
            (value === TITLE_NOT_SELECTED || !value) &&
            (first_name || last_name)
          ) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }

          if (value === TITLE_NOT_SELECTED) {
            return false;
          }
        }

        return true;
      }
    ),
    first_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, title, last_name } = this.parent;

        if (customer_group_firm) {
          if (
            !value &&
            ((title && title !== TITLE_NOT_SELECTED) || last_name)
          ) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        return true;
      }
    ),
    last_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, title, first_name } = this.parent;

        if (customer_group_firm) {
          if (
            !value &&
            ((title && title !== TITLE_NOT_SELECTED) || first_name)
          ) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        return true;
      }
    ),
    email: Yup.string().when('isEmailRequired', {
      is: true,
      then: () =>
        Yup.string().email(INVALID_EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
      otherwise: () => Yup.string()
    }),
    street: Yup.string().required(REQUIRED_MESSAGE),
    house_number: Yup.string()
      .matches(/^\d+$/, HOUSE_NUMBER_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    house_suffix: Yup.string(),
    post_code: Yup.string()
      .matches(/^[0-9]{5}$/, POSTCODE_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
    location: Yup.string().required(REQUIRED_MESSAGE)
  }
);

const propertyOwnerSchema: Yup.Schema<PropertyOwnerDetails> =
  Yup.object().shape({
    customer_group_firm: Yup.boolean(),
    company: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, title, first_name, last_name } =
          this.parent;

        if (!customer_group_firm) {
          return true;
        }

        if (!value && !(title && first_name && last_name)) {
          return false;
        }

        return true;
      }
    ),
    title: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, first_name, last_name } =
          this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }

          if (value === TITLE_NOT_SELECTED && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }

          if (value === TITLE_NOT_SELECTED) {
            return false;
          }
        }

        if (
          (value === TITLE_NOT_SELECTED || !value) &&
          (first_name || last_name)
        ) {
          return false;
        }

        return true;
      }
    ),
    first_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, title, last_name } = this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        if (!value && ((title && title !== TITLE_NOT_SELECTED) || last_name)) {
          return false;
        }

        return true;
      }
    ),
    last_name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { customer_group_firm, company, title, first_name } = this.parent;

        if (customer_group_firm) {
          if (!value && !company) {
            return false;
          }
        } else {
          if (!value) {
            return false;
          }
        }

        if (!value && ((title && title !== TITLE_NOT_SELECTED) || first_name)) {
          return false;
        }

        return true;
      }
    ),
    email: Yup.string()
      .email(INVALID_EMAIL_MESSAGE)
      .test('emailOrPhoneNumber', REQUIRED_MESSAGE, function (value) {
        const { phone_number } = this.parent;
        if (!value && !phone_number) {
          return false;
        }

        return true;
      }),
    phone_number: Yup.string()
      .matches(/^(?:\+\d+|0\d+)?$/, PHONE_NUMBER_INVALID_MESSAGE)
      .test('emailOrPhoneNumber', REQUIRED_MESSAGE, function (value) {
        const { email } = this.parent;
        if (!value && !email) {
          return false;
        }

        return true;
      }),
    street: Yup.string(),
    house_number: Yup.string()
      .matches(/^\d*$/, HOUSE_NUMBER_INVALID_MESSAGE)
      .notRequired(),
    house_suffix: Yup.string().notRequired(),
    post_code: Yup.string().matches(/^([0-9]{5})?$/u, POSTCODE_INVALID_MESSAGE),
    location: Yup.string().notRequired(),
    residentalUnitsCount: Yup.string()
      .required(RESIDENTAL_UNTIS_COUNT_INVALID_MESSAGE)
      .test(
        'is-number',
        RESIDENTAL_UNTIS_COUNT_INVALID_MESSAGE,
        (value) =>
          !isNaN(Number(value)) &&
          Number(value) > 0 &&
          Number.isInteger(Number(value))
      )
  });

const contactPersonDetailsSchema: Yup.Schema<ContactPersonDetails> =
  Yup.object().shape({
    title: Yup.string().required(REQUIRED_MESSAGE),
    first_name: Yup.string().required(REQUIRED_MESSAGE),
    last_name: Yup.string().required(REQUIRED_MESSAGE),
    email: Yup.string()
      .email(INVALID_EMAIL_MESSAGE)
      .test('emailOrPhoneNumber', REQUIRED_MESSAGE, function (value) {
        const { phone_number } = this.parent;
        if (!value && !phone_number) {
          return false;
        }

        return true;
      }),
    phone_number: Yup.string()
      .matches(/^(?:\+\d+|0\d+)?$/, PHONE_NUMBER_INVALID_MESSAGE)
      .test('emailOrPhoneNumber', REQUIRED_MESSAGE, function (value) {
        const { email } = this.parent;
        if (!value && !email) {
          return false;
        }

        return true;
      })
  });

const validationSchema = Yup.object({
  shippingEqualToCustomer: Yup.boolean(),
  installationAddressEqualToCustomer: Yup.boolean(),
  invoiceEqualToCustomer: Yup.boolean(),
  propertyOwnerEqualToCustomer: Yup.boolean(),
  contactPersonEqualToCustomer: Yup.boolean(),
  sepaEqualToCustomer: Yup.boolean(),
  sepaPaymentTerms: Yup.boolean().when('ibanRequired', {
    is: true,
    then: () =>
      Yup.boolean().test(
        'sepaPaymentTerms',
        REQUIRED_MESSAGE,
        (value) => value
      ),
    otherwise: () => Yup.string().notRequired()
  }),

  ibanRequired: Yup.boolean(),
  iban: Yup.string().when('ibanRequired', {
    is: true,
    then: () => Yup.string().required(REQUIRED_MESSAGE),
    otherwise: () => Yup.string().notRequired()
  }),
  bic: Yup.string().when('ibanRequired', {
    is: true,
    then: () => Yup.string().required(REQUIRED_MESSAGE),
    otherwise: () => Yup.string().notRequired()
  }),
  creditInstitution: Yup.string().when('ibanRequired', {
    is: true,
    then: () => Yup.string().required(REQUIRED_MESSAGE),
    otherwise: () => Yup.string().notRequired()
  }),
  personalInfo: userDetailsSchema,
  installationAddressData: Yup.object().when(
    'installationAddressEqualToCustomer',
    {
      is: false,
      then: () => installationAddressSchema,
      otherwise: () => installationAddressSchema.strip()
    }
  ),
  shippingData: Yup.object().when('shippingEqualToCustomer', {
    is: false,
    then: () => shippingDetailsSchema,
    otherwise: () => shippingDetailsSchema.strip()
  }),
  invoiceData: Yup.object().when('invoiceEqualToCustomer', {
    is: false,
    then: () => invoiceDetailsSchema,
    otherwise: () => invoiceDetailsSchema.strip()
  }),
  propertyOwnerData: Yup.object().when('propertyOwnerEqualToCustomer', {
    is: false,
    then: () => propertyOwnerSchema,
    otherwise: () => propertyOwnerSchema.strip()
  }),
  contactPersonData: Yup.object().when('contactPersonEqualToCustomer', {
    is: false,
    then: () => contactPersonDetailsSchema,
    otherwise: () => contactPersonDetailsSchema.strip()
  }),
  paymentData: Yup.object().when('sepaEqualToCustomer', {
    is: false,
    then: () => sepaDetailsSchema,
    otherwise: () => sepaDetailsSchema.strip()
  })
});

export default validationSchema;
