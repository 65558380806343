import SideBar from 'components/SideBar';
import Steps from 'components/Steps';
import { ScrollProvider } from 'contexts';

import './App.css';

const App = () => {
  return (
    <ScrollProvider>
      <div className="App">
        <SideBar />
        <Steps />
      </div>
    </ScrollProvider>
  );
};

export default App;
