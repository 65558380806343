import React from 'react';
import { Button } from 'antd';

import style from './style.module.css';
import { SecondaryButtonProps } from './interface';

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  onClick,
  children,
  className,
  size = 'middle'
}) => {
  return (
    <Button
      type="default"
      className={`${style.secondary_button} ${className ? className : ''}`}
      onClick={onClick}
      size={size}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
