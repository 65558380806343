import { useMemo, useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store/store';

import { Collapse } from 'antd';

import { formIsValid, validationErrors } from 'store/slices/validationSlice';

import PersonalDetails from './PersonalDetails/PersonalDetails';
import SepaDirectDebitMandate from './SepaDirectDebitMandate';
import ShippingDetails from './ShippingDetails';
import InvoiceDetails from './InvoiceDetails';
import PropertyOwner from './PropertyOwner';
import ContactPerson from './ContactPerson';
import ErrorMessage from 'components/ErrorMessage';

import {
  ContactIcon,
  ExpandIconClosed,
  ExpandIconOpened,
  InvoiceIcon,
  PersonalDetailsIcon,
  PropertyIcon,
  SepaDirectDebitIcon,
  ShippingIcon,
  AddressIcon
} from '../../../assets/Icons';

import ErrorIcon from 'assets/images/svg/error-icon.svg';

import style from './style.module.css';
import { createValidationErrorMessage, formatAddress } from '../utils';
import InstallationAddress from './InstallationAddress/InstallationAddress';
import {
  shortenAddress,
  getAddressFromQueryParameters
} from './InstallationAddress/utils';

const Payment = () => {
  const { Panel } = Collapse;

  const ibanOption = 'sepa-lastschrifteinzug';

  const sectionRefs = {
    shippingRef: useRef<HTMLInputElement>(null),
    invoiceRef: useRef<HTMLInputElement>(null),
    propertyOwnerRef: useRef<HTMLInputElement>(null),
    contactPersonRef: useRef<HTMLInputElement>(null)
  };

  const [sectionWithError, setSectionWithError] =
    useState<React.RefObject<HTMLInputElement> | null>(null);

  const {
    shippingData,
    invoiceData,
    contactPersonData,
    propertyOwnerData,
    clientAddressData,
    paymentData
  } = useSelector((state: RootState) => state.selectedData);

  const { selectedPaymentOption } = useSelector(
    (state: RootState) => state.iban
  );

  const errors = useSelector(validationErrors);

  const [activeSection, setActiveSection] = useState<string>('1');

  const formValid = useSelector(formIsValid);

  const validationErrorMessage = createValidationErrorMessage(errors);

  const onChange = (key: string | string[]) => {
    setActiveSection(key[0]);
  };

  const handleShowPersonalInfoError = useMemo(() => {
    if (errors?.personalInfo && Object.keys(errors?.personalInfo)?.length > 0) {
      setActiveSection('1');
      return true;
    } else {
      if (
        errors?.installationAddressData &&
        Object.keys(errors?.installationAddressData)?.length > 0 &&
        !clientAddressData?.equalToCustomer
      ) {
        if (!errors?.personalInfo) {
          setActiveSection('1');
        }
        return true;
      } else {
        return false;
      }
    }
  }, [
    errors.personalInfo,
    errors?.installationAddressData,
    clientAddressData?.equalToCustomer
  ]);

  const handleShowSepaError = useMemo(() => {
    if (errors?.sepaPaymentTerms) {
      if (!errors?.personalInfo && !errors.installationAddressData) {
        setActiveSection('2');
      }
      return true;
    }

    if (
      (errors?.bic || errors?.iban || errors?.creditInstitution) &&
      selectedPaymentOption === ibanOption
    ) {
      if (!errors?.personalInfo && !errors.installationAddressData) {
        setActiveSection('2');
      }
      return true;
    } else {
      if (
        errors?.paymentData &&
        Object.keys(errors?.paymentData)?.length > 0 &&
        !paymentData?.equalToCustomer
      ) {
        if (!errors?.personalInfo && !errors.installationAddressData) {
          setActiveSection('2');
        }
        return true;
      } else {
        return false;
      }
    }
  }, [errors, selectedPaymentOption]);

  const handleShowShippingError = useMemo(() => {
    if (
      errors?.shippingData &&
      Object.keys(errors?.shippingData)?.length > 0 &&
      !shippingData?.equalToCustomer
    ) {
      if (!errors?.personalInfo && !handleShowSepaError) {
        setActiveSection('3');
        setSectionWithError(sectionRefs.shippingRef);
      }
      return true;
    } else {
      return false;
    }
  }, [errors?.shippingData, shippingData?.equalToCustomer]);

  const handleShowInvoiceError = useMemo(() => {
    if (
      errors?.invoiceData &&
      Object.keys(errors?.invoiceData)?.length > 0 &&
      !invoiceData?.equalToCustomer
    ) {
      if (
        !errors?.personalInfo &&
        !handleShowSepaError &&
        !errors?.shippingData
      ) {
        setActiveSection('4');
        setSectionWithError(sectionRefs.invoiceRef);
      }
      return true;
    } else {
      return false;
    }
  }, [errors?.invoiceData, invoiceData?.equalToCustomer]);

  const handleShowPropertyOwnerError = useMemo(() => {
    if (
      errors?.propertyOwnerData &&
      Object.keys(errors?.propertyOwnerData)?.length > 0 &&
      !propertyOwnerData?.equalToCustomer
    ) {
      if (
        !errors?.personalInfo &&
        !handleShowSepaError &&
        !errors?.shippingData &&
        !errors?.invoiceData
      ) {
        setActiveSection('5');
        setSectionWithError(sectionRefs.propertyOwnerRef);
      }
      return true;
    } else {
      return false;
    }
  }, [errors?.propertyOwnerData, propertyOwnerData?.equalToCustomer]);

  const handleShowContactPersonError = useMemo(() => {
    if (
      errors?.contactPersonData &&
      Object.keys(errors?.contactPersonData)?.length > 0 &&
      !contactPersonData?.equalToCustomer
    ) {
      if (
        !errors?.personalInfo &&
        !handleShowSepaError &&
        !errors?.shippingData &&
        !errors?.invoiceData &&
        !errors?.propertyOwnerData
      ) {
        setActiveSection('6');
        setSectionWithError(sectionRefs.contactPersonRef);
      }
      return true;
    } else {
      return false;
    }
  }, [errors?.contactPersonData, contactPersonData?.equalToCustomer]);

  useEffect(() => {
    if (sectionWithError && sectionWithError.current) {
      sectionWithError.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [sectionWithError?.current]);

  const { street, houseNumber, houseNumberSuffix, postcode, location } =
    getAddressFromQueryParameters();

  const installationAddress = formatAddress(
    street,
    houseNumber,
    houseNumberSuffix,
    postcode,
    location
  );

  const shortenInstallationAddress: string = shortenAddress(
    installationAddress,
    20
  );

  return (
    <div className={style.payment_container}>
      {!formValid && <ErrorMessage errorMessage={validationErrorMessage} />}

      <Collapse
        defaultActiveKey="1"
        onChange={onChange}
        accordion
        ghost
        bordered={true}
        expandIcon={({ isActive }) =>
          isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
        }
        expandIconPosition="end"
        activeKey={activeSection}
      >
        <Panel
          header={
            <div className="collapse_header_wrapper">
              <AddressIcon />
              <h2 className={style.accordion_heading}>
                Installationsanschrift{' '}
                <span className={style.bracket_text}>
                  {`(${shortenInstallationAddress})`}
                </span>
              </h2>
            </div>
          }
          key="0"
        >
          <InstallationAddress />
        </Panel>
        <Panel
          header={
            <div className="collapse_header_wrapper">
              <PersonalDetailsIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowPersonalInfoError ? style.error_style_title : ''}`}
              >
                Auftraggeber
              </h2>

              {handleShowPersonalInfoError && (
                <img src={ErrorIcon} alt="error_icon" />
              )}
            </div>
          }
          key="1"
        >
          <PersonalDetails />
        </Panel>

        <Panel
          header={
            <div className="collapse_header_wrapper">
              <SepaDirectDebitIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowSepaError ? style.error_style_title : ''}`}
              >
                SEPA Einzugsermächtigung
              </h2>
              {handleShowSepaError && <img src={ErrorIcon} alt="error_icon" />}
            </div>
          }
          key="2"
        >
          <SepaDirectDebitMandate />
        </Panel>

        <Panel
          header={
            <div className="collapse_header_wrapper">
              <ShippingIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowShippingError ? style.error_style_title : ''}`}
              >
                Versandinformationen{' '}
                <span className={style.bracket_text}>
                  {`(${shippingData?.equalToCustomer ? 'Mit Auftraggeber identisch' : 'Sonstige'})`}
                </span>
              </h2>

              {handleShowShippingError && (
                <img src={ErrorIcon} alt="error_icon" />
              )}
            </div>
          }
          key="3"
          ref={sectionRefs.shippingRef}
        >
          <ShippingDetails />
        </Panel>

        <Panel
          header={
            <div className="collapse_header_wrapper">
              <InvoiceIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowInvoiceError ? style.error_style_title : ''}`}
              >
                Rechnungsadresse{' '}
                <span className={style.bracket_text}>
                  {' '}
                  {`(${invoiceData?.equalToCustomer ? 'Mit Auftraggeber identisch' : 'Sonstige'})`}
                </span>
              </h2>

              {handleShowInvoiceError && (
                <img src={ErrorIcon} alt="error_icon" />
              )}
            </div>
          }
          key="4"
          ref={sectionRefs.invoiceRef}
        >
          <InvoiceDetails />
        </Panel>

        <Panel
          header={
            <div className="collapse_header_wrapper">
              <PropertyIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowPropertyOwnerError ? style.error_style_title : ''}`}
              >
                Grundstückseigentümer{' '}
                <span
                  className={style.bracket_text}
                >{`(${propertyOwnerData?.equalToCustomer ? 'Mit Auftraggeber identisch' : 'Sonstige'})`}</span>
              </h2>

              {handleShowPropertyOwnerError && (
                <img src={ErrorIcon} alt="error_icon" />
              )}
            </div>
          }
          key="5"
          ref={sectionRefs.propertyOwnerRef}
        >
          <PropertyOwner />
        </Panel>

        <Panel
          header={
            <div className="collapse_header_wrapper">
              <ContactIcon />
              <h2
                className={`${style.accordion_heading} ${handleShowContactPersonError ? style.error_style_title : ''}`}
              >
                Kontaktperson für Hausbegehung
                <span className={style.bracket_text}>
                  {' '}
                  {`(${contactPersonData.equalToCustomer ? 'Mit Auftraggeber identisch' : 'Sonstige'})`}
                </span>
              </h2>

              {handleShowContactPersonError && (
                <img src={ErrorIcon} alt="error_icon" />
              )}
            </div>
          }
          key="6"
          ref={sectionRefs.contactPersonRef}
        >
          <ContactPerson />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Payment;
