import {
  MUENET_ADDRESS_CHECK_PRODUCTION_URL,
  MUENET_ADDRESS_CHECK_QS_URL
} from 'constants/Constants';
import {
  ARGenerateSessionRequestData,
  ARGenerateSessionResponseData,
  PackageAddressInfo
} from 'types/bundle';

export const updateSessionIdInURL = (
  generateSessionData: ARGenerateSessionResponseData
) => {
  const currentUrl = window.location.href;

  const newSessionId = generateSessionData?.new_session_id;

  if (newSessionId) {
    const updatedUrl = currentUrl.replace(
      /([?&]session_id=)[^&]*/,
      `$1${newSessionId}`
    );

    return updatedUrl;
  }

  return currentUrl;
};

export const createARGenerateSessionRequestData = (
  packageAddressInfo: PackageAddressInfo
): ARGenerateSessionRequestData => ({
  address_id: packageAddressInfo?.address_id || '',
  hashkey: decodeURIComponent(packageAddressInfo?.hashkey || '')
});

export const getCheckAddressUrl = () => {
  return process.env.NODE_ENV === 'development'
    ? `${MUENET_ADDRESS_CHECK_QS_URL}`
    : `${MUENET_ADDRESS_CHECK_PRODUCTION_URL}`;
};

export const isNewSessionGenerated = (
  payload: ARGenerateSessionResponseData
) => {
  return payload?.new_session_id;
};
