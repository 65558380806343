import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'store/store';

interface InitialState {
  errorPlace: string;
  errorMessage: string;
}

const errorSlice = createSlice({
  name: 'errors',
  initialState: {
    errorPlace: '',
    errorMessage: ''
  } as InitialState,
  reducers: {
    setErrorPlace: (state, action) => {
      state.errorPlace = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setErrorPlace } = errorSlice.actions;

export const setErrorMessage =
  (message: string) => async (dispatch: AppDispatch) => {
    return new Promise<void>((resolve) => {
      dispatch(errorSlice.actions.setErrorMessage(message));
      resolve();
    });
  };

export const errorPlace = (state: { errors: InitialState }) =>
  state.errors.errorPlace;
export const errorMessage = (state: { errors: InitialState }) =>
  state.errors.errorMessage;

export default errorSlice.reducer;
