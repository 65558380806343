import React from 'react';
import { Button } from 'antd';

import style from './style.module.css';
import { PrimaryButtonProps } from './interface';

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onClick,
  children,
  className,
  size = 'middle'
}) => {
  return (
    <Button
      type="default"
      className={`${style.primary_button} ${className ? className : ''}`}
      onClick={onClick}
      size={size}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
