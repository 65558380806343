import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkSessionValidityInAR } from 'store/slices/arSessionSlice';
import { AppDispatch, RootState } from 'store/store';
import { SessionValidityRequestData } from 'types/bundle';

const useCheckSessionValidityInARData = () => {
  const dispatch: AppDispatch = useDispatch();

  const checkSessionValidityInARStatus = useSelector(
    (state: RootState) => state.arSession.status
  );

  const handleCheckSessionValidityInARAction = useCallback(
    (sessionValidityData: SessionValidityRequestData) =>
      dispatch(checkSessionValidityInAR(sessionValidityData)),
    [dispatch]
  );

  return {
    checkSessionValidityInARStatus,
    handleCheckSessionValidityInARAction
  };
};

export default useCheckSessionValidityInARData;
