import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../api-config';
import { CategoryWithProducts } from 'types/bundle';

interface InitialState {
  categoriesWithProducts: CategoryWithProducts[];
  data: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

export const getCategories = createAsyncThunk<
  any,
  { region: string | number | null; selectedPackage: string | number | null }
>('categories/getCategories', async ({ region, selectedPackage }) => {
  const response = await axios.get<any[]>(
    `${API_URL}/v2/products/${region}/${selectedPackage}`
  );
  return response.data;
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categoriesWithProducts: [],
    data: [],
    status: 'idle',
    error: ''
  } as InitialState,
  reducers: {
    setCategoriesWithProduct: (
      state,
      action: PayloadAction<CategoryWithProducts[]>
    ) => {
      state.categoriesWithProducts = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCategories.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      });
  }
});

export const selectedCurrentPackage = (state: { categories: InitialState }) => {
  const data = state.categories?.data;
  if (!data || !data.packages || data.packages.length === 0) {
    return null;
  }

  return data.packages[0] ?? null;
};

export const selectedCategoriesWithProducts = (state: {
  selectedData: InitialState;
}) => state.selectedData.categoriesWithProducts;

export const { setCategoriesWithProduct } = categoriesSlice.actions;

export default categoriesSlice.reducer;
