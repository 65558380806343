import { createSlice } from '@reduxjs/toolkit';
import { UserDetails as PersonalDetails } from 'components/Step/Payment/PersonalDetails/interface';
import { UserDetails as InvoiceDetails } from 'components/Step/Payment/InvoiceDetails/interface';
import { UserDetails as ShippingDetails } from 'components/Step/Payment/ShippingDetails/interface';
import { UserDetails as PropertyOwnerDetails } from 'components/Step/Payment/PropertyOwner/interface';
import { UserDetails as ContactPersonDetails } from 'components/Step/Payment/ContactPerson/interface';
import { InstallationAddressDetails } from 'components/Step/Payment/InstallationAddress/interface';
import { SepaDetails } from 'components/Step/Payment/validationSchema';
import { TermsAndConditionsAgreements } from 'components/Step/OrderSummary/termsAndConditionsValidationSchema';
import { ExistingConnectionsValidationData } from 'components/Step/ExistingConnection/existingConnectionValidationSchema';

interface InitialState {
  errors: {
    personalInfo: PersonalDetails;
    installationAddressData: InstallationAddressDetails;
    sepaPaymentTerms: boolean;
    iban: string;
    bic: string;
    creditInstitution: string;
    paymentData: SepaDetails;
    shippingData: ShippingDetails;
    invoiceData: InvoiceDetails;
    propertyOwnerData: PropertyOwnerDetails;
    contactPersonData: ContactPersonDetails;
  };
  termsAndConditions: TermsAndConditionsAgreements;
  existingConnectionsErrors: ExistingConnectionsValidationData;
  formIsValid: boolean;
  emailRequired: boolean;
}

const validationSlice = createSlice({
  name: 'validationErrors',
  initialState: {
    errors: {
      personalInfo: {},
      installationAddressData: {},
      sepaPaymentTerms: false,
      iban: '',
      bic: '',
      creditInstitution: '',
      paymentData: {},
      shippingData: {},
      invoiceData: {},
      propertyOwnerData: {},
      contactPersonData: {}
    },
    termsAndConditions: {},
    existingConnectionsErrors: {},
    formIsValid: true,
    emailRequired: true
  } as InitialState,
  reducers: {
    setValidationErrors: (state, action) => {
      state.errors = action.payload;
    },
    setIsFormValid: (state, action) => {
      state.formIsValid = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.termsAndConditions = action.payload;
    },
    setExistingConnectionsErrors: (state, action) => {
      state.existingConnectionsErrors = action.payload;
    },
    setIsEmailRequired: (state, action) => {
      state.emailRequired = action.payload;
    }
  }
});

export const {
  setValidationErrors,
  setIsFormValid,
  setTermsAndConditions,
  setExistingConnectionsErrors,
  setIsEmailRequired
} = validationSlice.actions;

export const validationErrors = (state: { validationErrors: InitialState }) =>
  state.validationErrors.errors;

export const formIsValid = (state: { validationErrors: InitialState }) =>
  state.validationErrors.formIsValid;

export const termsAndConditions = (state: { validationErrors: InitialState }) =>
  state.validationErrors.termsAndConditions;

export const existingConnectionsErrors = (state: {
  validationErrors: InitialState;
}) => state.validationErrors.existingConnectionsErrors;

export default validationSlice.reducer;
