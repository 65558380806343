import {
  TAX_INCLUDED_MESSAGE,
  TAX_NOT_INCLUDED_MESSAGE,
  TITLE_NOT_SELECTED
} from 'constants/Constants';

import { ServiceType } from 'store/slices/selectedServices';

export const sortProductsByCategoryId = (
  a: ServiceType,
  b: ServiceType
): number => {
  return a.service.catID - b.service.catID;
};

export const getTaxInfoMessage = (taxIncluded: boolean): string => {
  return taxIncluded ? TAX_INCLUDED_MESSAGE : TAX_NOT_INCLUDED_MESSAGE;
};

export const firstNameLastNameExists = (
  title?: string,
  firstName?: string,
  lastName?: string
): boolean => {
  if (!(title && firstName && lastName)) {
    return false;
  }

  return true;
};

export const formatName = (
  title?: string,
  first_name?: string,
  last_name?: string
): string => {
  if (!(title && first_name && last_name)) {
    return '';
  }

  return `${title ? `${title} ` : ''}${first_name} ${last_name}`;
};

export const getAndFormatFullPersonalInfo = (
  title?: string,
  firstName?: string,
  lastName?: string
) => {
  return firstNameLastNameExists(title, firstName, lastName)
    ? formatName(title, firstName, lastName)
    : '';
};

export const isNumber = (value: any): boolean => {
  try {
    if (isNaN(value)) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getTitleOptions = (customer_group_firm: boolean) => {
  const options = [];

  if (customer_group_firm) {
    options.push({
      label: TITLE_NOT_SELECTED,
      value: TITLE_NOT_SELECTED
    });
  }

  options.push(
    { label: 'Herr', value: 'Herr' },
    { label: 'Frau', value: 'Frau' }
  );

  return options;
};
