import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import bundleReducer from './slices/bundleSlice';
import selectedDataReducer from './slices/selectedDataSlice';
import pricesReducer from './slices/pricesSlice';
import errorReducer from './slices/errorSlice';
import ibanReducer from './slices/ibanSlice';
import finalSubmitReducer from './slices/submitSlice';
import providerReducer from './slices/previousProvidersSlice';
import validationErrorReducer from './slices/validationSlice';
import prfSubmitReducer from './slices/pdfSubmitSlice';
import mobileReducer from './slices/mobileSlice';
import fileUploadReducer from './slices/fileUploadSlice';
import donationCharitiesReducer from './slices/donationsSlice';
import categoriesReduces from './slices/categoriesSlice';
import selectedServicesReducer from './slices/selectedServices';
import portingOptionReducer from './slices/portingOptionSlice';
import shippingProductReducer from './slices/shippingProductSlice';
import arSessionReducer from './slices/arSessionSlice';

const appReducer = combineReducers({
  bundle: bundleReducer,
  selectedData: selectedDataReducer,
  prices: pricesReducer,
  errors: errorReducer,
  iban: ibanReducer,
  finalSubmit: finalSubmitReducer,
  previousProvider: providerReducer,
  validationErrors: validationErrorReducer,
  pdfSubmit: prfSubmitReducer,
  mobile: mobileReducer,
  fileUpload: fileUploadReducer,
  donationCharities: donationCharitiesReducer,
  categories: categoriesReduces,
  selectedServices: selectedServicesReducer,
  portingOption: portingOptionReducer,
  shippingProduct: shippingProductReducer,
  arSession: arSessionReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const resetStore = () => {
  persistor.purge();
  store.dispatch({ type: 'RESET' });
};
