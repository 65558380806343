import React, { useState } from 'react';
import { Modal } from 'antd';

import { SessionInvalidModalProps } from './interface';
import {
  createARGenerateSessionRequestData,
  updateSessionIdInURL,
  getCheckAddressUrl,
  isNewSessionGenerated
} from './utils';
import SecondaryButton from 'components/SecondaryButton';
import PrimaryButton from 'components/PrimaryButton';
import { ERROR_OCCURRED_MESSAGE } from 'constants/Constants';
import useGenerateNewSessionIdData from 'hooks/useGenerateNewSessionId';
import { resetStore } from 'store/store';

const InvalidSessionModal: React.FC<SessionInvalidModalProps> = ({
  isVisible,
  packageAddressInfo
}) => {
  const { handleGenerateNewSessionIdAction } = useGenerateNewSessionIdData();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const redirectToUrl = (url: string) => {
    window.location.href = url;
  };

  const handleConfirm = async () => {
    setErrorMessage(null);
    try {
      const generateSessionData =
        createARGenerateSessionRequestData(packageAddressInfo);

      const actionResult =
        await handleGenerateNewSessionIdAction(generateSessionData);

      if (actionResult.meta.requestStatus !== 'fulfilled') {
        setErrorMessage(
          typeof actionResult.payload === 'string'
            ? actionResult.payload
            : ERROR_OCCURRED_MESSAGE
        );
        return;
      }

      if (
        typeof actionResult.payload !== 'object' ||
        actionResult.payload === null
      ) {
        setErrorMessage(ERROR_OCCURRED_MESSAGE);
        return;
      }

      if (!isNewSessionGenerated(actionResult.payload)) {
        setErrorMessage(ERROR_OCCURRED_MESSAGE);
        return;
      }

      const newUrl = updateSessionIdInURL(actionResult.payload);

      resetStore();
      redirectToUrl(newUrl);
    } catch (err) {
      setErrorMessage(ERROR_OCCURRED_MESSAGE);
    }
  };

  const handleCancel = () => {
    redirectToUrl(
      `${getCheckAddressUrl()}/?package=${packageAddressInfo?.package}`
    );
  };

  const handleManualCheck = () => {
    redirectToUrl(getCheckAddressUrl());
  };

  const renderFooterButtons = () => {
    if (errorMessage) {
      return (
        <PrimaryButton key="manual" onClick={handleManualCheck}>
          Zur Webseite
        </PrimaryButton>
      );
    }

    return [
      <SecondaryButton key="no" onClick={handleCancel}>
        Nein, abbrechen
      </SecondaryButton>,
      <PrimaryButton key="yes" onClick={handleConfirm}>
        Ja, neu anlegen
      </PrimaryButton>
    ];
  };

  return (
    <Modal
      className="invalid-session"
      title={errorMessage ? 'Fehler' : 'Vertrag für Adresse bereits vorhanden'}
      open={isVisible}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      footer={renderFooterButtons()}
    >
      {errorMessage ? (
        <p>
          Es ist ein Fehler aufgetreten. Bitte geben Sie die Adresse auf der
          Webseite neu ein und versuchen es dann erneut.
        </p>
      ) : (
        <p>
          Für die ausgewählte Adresse wurde bereits ein Vertrag von Ihnen
          eingereicht. Möchten Sie einen weiteren Auftrag für die Adresse
          erstellen?
        </p>
      )}
    </Modal>
  );
};

export default InvalidSessionModal;
