import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectedShippingData,
  setShippingData
} from 'store/slices/selectedDataSlice';
import { validationErrors } from 'store/slices/validationSlice';

import { UserDetails } from './interface';
import { Packages, ShippingData } from 'types/bundle';

import { Row, Col, Radio, RadioChangeEvent } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import style from './style.module.css';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import ErrorMessage from 'components/ErrorMessage';
import {
  ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE,
  ENTER_PERSONAL_INFO_MESSAGE,
  TITLE_NOT_SELECTED
} from 'constants/Constants';
import { getTitleOptions, isNumber } from 'utils/Utils';

const ShippingDetails = () => {
  const dispatch = useDispatch();

  const shippingInfo: ShippingData = useSelector(selectedShippingData);

  const errors = useSelector(validationErrors);

  const [selectedOption, setSelectedOption] = useState<boolean>(
    shippingInfo.equalToCustomer
  );

  const [userData, setUserData] = useState<UserDetails>(shippingInfo);

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value
    });

    dispatch(
      setShippingData({
        ...shippingInfo,
        [event.target.name]: event.target.value
      })
    );
  };

  const onTitleChange = (event: string) => {
    setUserData({ ...userData, title: event });

    dispatch(setShippingData({ ...shippingInfo, title: event }));
  };

  const onOwnerChange = (event: RadioChangeEvent) => {
    const { value } = event.target;

    setSelectedOption(value);

    dispatch(
      setShippingData({
        ...shippingInfo,
        equalToCustomer: value
      })
    );
  };

  return (
    <div className={style.shipping_detail_container}>
      <Radio.Group
        value={selectedOption}
        onChange={onOwnerChange}
        className="margin_bottom_l"
      >
        <Row justify={'start'} gutter={[0, 24]} className={style.radio_wrapper}>
          <Col span={24} xl={6}>
            <Radio value={true}>Auftraggeber</Radio>
          </Col>
          <Col span={24} xl={12}>
            <Radio value={false}>Sonstige (bitte angeben)</Radio>
          </Col>
        </Row>
      </Radio.Group>

      {!selectedOption && (
        <>
          {(errors.shippingData?.company ||
            (errors.shippingData?.title && userData.title === '') ||
            (errors.shippingData?.first_name && userData.first_name === '') ||
            (errors.shippingData?.last_name && userData.last_name === '')) && (
            <div className="error_message_wrapper">
              <ErrorMessage
                errorMessage={
                  currentPackage?.customer_group_firm
                    ? ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE
                    : ENTER_PERSONAL_INFO_MESSAGE
                }
              />
            </div>
          )}

          <Row className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24}>
              <FloatingLabelInput
                label={`Firma${currentPackage?.customer_group_firm ? '*' : ''}`}
                value={userData?.company}
                onChangeHandler={onChangeHandler}
                name="company"
                errorStatus={errors?.shippingData?.company ? 'error' : ''}
                errorMessage={errors?.shippingData?.company}
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={8} md={6}>
              <FloatingLabelSelect
                label="Anrede*"
                value={userData.title}
                options={getTitleOptions(true)} // Company field is always visible regardles of currentPackage?.customer_group_firm
                onChangeHandler={onTitleChange}
                errorStatus={
                  errors?.shippingData?.title &&
                  (userData.title === '' ||
                    userData.title === TITLE_NOT_SELECTED)
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.shippingData?.title &&
                  (userData.title === '' ||
                    userData.title === TITLE_NOT_SELECTED)
                    ? errors?.shippingData?.title
                    : undefined
                }
              />
            </Col>
            <Col span={16} md={9}>
              <FloatingLabelInput
                label="Vorname*"
                value={userData.first_name}
                onChangeHandler={onChangeHandler}
                name="first_name"
                errorStatus={
                  errors?.shippingData?.first_name && userData.first_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.shippingData?.first_name && userData.first_name === ''
                    ? errors?.shippingData?.first_name
                    : null
                }
              />
            </Col>
            <Col span={24} md={9}>
              <FloatingLabelInput
                label="Nachname*"
                value={userData.last_name}
                onChangeHandler={onChangeHandler}
                name="last_name"
                errorStatus={
                  errors?.shippingData?.last_name && userData.last_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.shippingData?.last_name && userData.last_name === ''
                    ? errors?.shippingData?.last_name
                    : null
                }
              />
            </Col>
          </Row>
          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={16} md={12}>
              <FloatingLabelInput
                label="Straße*"
                value={userData.street}
                onChangeHandler={onChangeHandler}
                name="street"
                errorStatus={
                  errors?.shippingData?.street && userData.street === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.shippingData?.street && userData.street === ''
                    ? errors?.shippingData?.street
                    : null
                }
              />
            </Col>
            <Col span={8} md={6}>
              <FloatingLabelInput
                label="Hausnummer*"
                value={userData.house_number}
                onChangeHandler={onChangeHandler}
                name="house_number"
                errorStatus={
                  (errors?.shippingData?.house_number &&
                    userData.house_number === '') ||
                  (userData?.house_number && !isNumber(userData?.house_number))
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.shippingData?.house_number &&
                    userData.house_number === '') ||
                  !isNumber(userData?.house_number)
                    ? (errors?.shippingData?.house_number as string)
                    : null
                }
              />
            </Col>
            <Col span={24} md={6}>
              <FloatingLabelInput
                label="Hausnummerzusatz (optional)"
                value={userData.house_suffix}
                onChangeHandler={onChangeHandler}
                name="house_suffix"
              />
            </Col>
          </Row>
          <Row gutter={12} className="margin_bottom_m">
            <Col span={10} md={12}>
              <FloatingLabelInput
                label="Postleitzahl*"
                value={userData.post_code}
                onChangeHandler={onChangeHandler}
                name="post_code"
                errorStatus={
                  (errors?.shippingData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code &&
                    userData?.post_code?.length !== 5)
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.shippingData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code?.length !== 5)
                    ? errors?.shippingData?.post_code
                    : null
                }
              />
            </Col>
            <Col span={14} md={12}>
              <FloatingLabelInput
                label="Ort*"
                value={userData.location}
                onChangeHandler={onChangeHandler}
                name="location"
                errorStatus={
                  errors?.shippingData?.location && userData.location === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.shippingData?.location && userData.location === ''
                    ? errors?.shippingData?.location
                    : null
                }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ShippingDetails;
