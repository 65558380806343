import React, { createContext, useRef } from 'react';

type ScrollContextType = {
  registerRef: (key: string, ref: HTMLElement | null) => void;
  scrollTo: (key: string, offset?: number) => void;
};

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const refs = useRef<Record<string, HTMLElement | null>>({});

  const registerRef = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      refs.current[key] = ref;
    } else {
      delete refs.current[key];
    }
  };

  const scrollTo = (key: string, offset = 0) => {
    const element = refs.current[key];
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  };

  return (
    <ScrollContext.Provider value={{ registerRef, scrollTo }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;
