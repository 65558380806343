import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectedPropertyOwnerData,
  setPropertyOwnerData
} from 'store/slices/selectedDataSlice';
import { validationErrors } from 'store/slices/validationSlice';

import { Packages, PropertyOwnerData } from 'types/bundle';

import { Row, Col, Radio, RadioChangeEvent } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';
import {
  REQUIRED_EMAIL_OR_NUMBER,
  REQUIRED_MESSAGE
} from '../validationSchema';

import style from './style.module.css';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import {
  ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE,
  ENTER_PERSONAL_INFO_MESSAGE,
  TITLE_NOT_SELECTED
} from 'constants/Constants';
import { getTitleOptions, isNumber } from 'utils/Utils';

const PropertyOwner = () => {
  const dispatch = useDispatch();

  const errors = useSelector(validationErrors);

  const propertyOwnerInfo: PropertyOwnerData = useSelector(
    selectedPropertyOwnerData
  );

  const [selectedOption, setSelectedOption] = useState<boolean>(
    propertyOwnerInfo.equalToCustomer
  );

  const [userData, setUserData] =
    useState<PropertyOwnerData>(propertyOwnerInfo);

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email' || name === 'phone_number') {
      setUserData({
        ...userData,
        [name]: value.replace(/\s+/g, '')
      });

      dispatch(
        setPropertyOwnerData({
          ...propertyOwnerInfo,
          [name]: value.replace(/\s+/g, '')
        })
      );
    } else {
      setUserData({
        ...userData,
        [name]: value
      });

      dispatch(
        setPropertyOwnerData({
          ...propertyOwnerInfo,
          [name]: value
        })
      );
    }
  };

  const onTitleChange = (event: string) => {
    setUserData({ ...propertyOwnerInfo, title: event });

    dispatch(setPropertyOwnerData({ ...propertyOwnerInfo, title: event }));
  };

  const onOwnerChange = (event: RadioChangeEvent) => {
    setSelectedOption(event.target.value);

    dispatch(
      setPropertyOwnerData({
        ...propertyOwnerInfo,
        equalToCustomer: event.target.value,
        residentalUnitsCount: '1'
      })
    );
  };

  return (
    <div className={style.property_owner_container}>
      <Radio.Group
        value={selectedOption}
        onChange={onOwnerChange}
        className="margin_bottom_l"
      >
        <Row justify={'start'} gutter={[0, 24]} className={style.radio_wrapper}>
          <Col span={24} xl={6}>
            <Radio value={true}>Auftraggeber</Radio>
          </Col>
          <Col span={24} xl={12}>
            <Radio value={false}>Sonstige (bitte angeben)</Radio>
          </Col>
        </Row>
      </Radio.Group>

      {((!selectedOption && errors?.propertyOwnerData?.email) ||
        (!selectedOption && errors?.propertyOwnerData?.phone_number)) && (
        <ErrorMessage errorMessage={REQUIRED_EMAIL_OR_NUMBER}></ErrorMessage>
      )}

      {!selectedOption && (
        <>
          {(errors.propertyOwnerData?.company ||
            (errors.propertyOwnerData?.title && userData.title === '') ||
            (errors.propertyOwnerData?.first_name &&
              userData.first_name === '') ||
            (errors.propertyOwnerData?.last_name &&
              userData.last_name === '')) && (
            <div className="error_message_wrapper">
              <ErrorMessage
                errorMessage={
                  currentPackage?.customer_group_firm
                    ? ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE
                    : ENTER_PERSONAL_INFO_MESSAGE
                }
              />
            </div>
          )}

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label={`Firma${currentPackage?.customer_group_firm ? '*' : ''}`}
                value={userData?.company}
                onChangeHandler={onChangeHandler}
                name="company"
                errorStatus={errors?.propertyOwnerData?.company ? 'error' : ''}
                errorMessage={errors?.propertyOwnerData?.company}
              />
            </Col>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="Anzahl Wohneinheiten"
                value={userData?.residentalUnitsCount}
                onChangeHandler={onChangeHandler}
                name="residentalUnitsCount"
                errorStatus={
                  errors?.propertyOwnerData?.residentalUnitsCount ? 'error' : ''
                }
                errorMessage={errors?.propertyOwnerData?.residentalUnitsCount?.toString()}
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={8} md={6}>
              <FloatingLabelSelect
                label="Anrede*"
                value={userData.title}
                options={getTitleOptions(true)}
                onChangeHandler={onTitleChange}
                errorStatus={
                  errors?.propertyOwnerData?.title &&
                  (userData.title === '' ||
                    userData.title === TITLE_NOT_SELECTED)
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.propertyOwnerData?.title &&
                  (userData.title === '' ||
                    userData.title === TITLE_NOT_SELECTED)
                    ? errors?.propertyOwnerData?.title
                    : undefined
                }
              />
            </Col>
            <Col span={16} md={9}>
              <FloatingLabelInput
                label="Vorname*"
                value={userData.first_name}
                onChangeHandler={onChangeHandler}
                name="first_name"
                errorStatus={
                  errors?.propertyOwnerData?.first_name &&
                  userData.first_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.propertyOwnerData?.first_name &&
                  userData.first_name === ''
                    ? REQUIRED_MESSAGE
                    : ''
                }
              />
            </Col>
            <Col span={24} md={9}>
              <FloatingLabelInput
                label="Nachname*"
                value={userData.last_name}
                onChangeHandler={onChangeHandler}
                name="last_name"
                errorStatus={
                  errors?.propertyOwnerData?.last_name &&
                  userData.last_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.propertyOwnerData?.last_name &&
                  userData.last_name === ''
                    ? errors?.propertyOwnerData?.last_name
                    : null
                }
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="E-Mail*"
                value={userData.email}
                onChangeHandler={onChangeHandler}
                name="email"
                errorStatus={
                  errors?.propertyOwnerData?.email &&
                  !propertyOwnerInfo?.phone_number
                    ? 'error'
                    : errors?.propertyOwnerData?.email &&
                        errors?.propertyOwnerData?.phone_number
                      ? 'error'
                      : errors?.propertyOwnerData?.email
                        ? 'error'
                        : ''
                }
                errorMessage={
                  errors?.propertyOwnerData?.email &&
                  !propertyOwnerInfo?.phone_number
                    ? errors?.propertyOwnerData?.email
                    : errors?.propertyOwnerData?.email &&
                        errors?.propertyOwnerData?.phone_number
                      ? errors?.propertyOwnerData?.email
                      : errors?.propertyOwnerData?.email
                        ? errors?.propertyOwnerData?.email
                        : ''
                }
              />
            </Col>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="Mobiltelefonnummer für Nachfragen*"
                value={userData.phone_number}
                onChangeHandler={onChangeHandler}
                name="phone_number"
                errorStatus={
                  errors?.propertyOwnerData?.phone_number &&
                  !propertyOwnerInfo?.email
                    ? 'error'
                    : errors?.propertyOwnerData?.phone_number &&
                        errors?.propertyOwnerData?.email
                      ? 'error'
                      : errors?.propertyOwnerData?.phone_number
                        ? 'error'
                        : ''
                }
                errorMessage={
                  errors?.propertyOwnerData?.phone_number &&
                  !propertyOwnerInfo?.email
                    ? errors?.propertyOwnerData?.phone_number
                    : errors?.propertyOwnerData?.phone_number &&
                        errors?.propertyOwnerData?.email
                      ? errors?.propertyOwnerData?.phone_number
                      : errors?.propertyOwnerData?.phone_number
                        ? errors?.propertyOwnerData?.phone_number
                        : ''
                }
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={16} md={12}>
              <FloatingLabelInput
                label="Straße"
                value={userData.street}
                onChangeHandler={onChangeHandler}
                name="street"
              />
            </Col>
            <Col span={8} md={6}>
              <FloatingLabelInput
                label="Hausnummer"
                value={userData.house_number}
                onChangeHandler={onChangeHandler}
                name="house_number"
                errorStatus={
                  (errors?.propertyOwnerData?.house_number &&
                    userData.house_number === '') ||
                  (userData?.house_number && !isNumber(userData?.house_number))
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.propertyOwnerData?.house_number &&
                    userData.house_number === '') ||
                  !isNumber(userData?.house_number)
                    ? (errors?.propertyOwnerData?.house_number as string)
                    : null
                }
              />
            </Col>
            <Col span={24} md={6}>
              <FloatingLabelInput
                label="Hausnummerzusatz (optional)"
                value={userData.house_suffix}
                onChangeHandler={onChangeHandler}
                name="house_suffix"
              />
            </Col>
          </Row>

          <Row gutter={12} className="margin_bottom_m">
            <Col span={10} md={12}>
              <FloatingLabelInput
                label="Postleitzahl"
                value={userData.post_code}
                onChangeHandler={onChangeHandler}
                name="post_code"
                errorStatus={
                  (errors?.propertyOwnerData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code &&
                    userData?.post_code?.length !== 5)
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.propertyOwnerData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code?.length !== 5)
                    ? errors?.propertyOwnerData?.post_code
                    : null
                }
              />
            </Col>
            <Col span={14} md={12}>
              <FloatingLabelInput
                label="Ort"
                value={userData.location}
                onChangeHandler={onChangeHandler}
                name="location"
              />
            </Col>
          </Row>

          <p className="package_speed">*Pflichtfeld</p>
        </>
      )}
    </div>
  );
};

export default PropertyOwner;
