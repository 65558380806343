import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectedContactPersonData,
  setContactPersonData
} from 'store/slices/selectedDataSlice';

import { ContactPersonData } from 'types/bundle';

import { Row, Col, Radio, RadioChangeEvent } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import style from './style.module.css';
import { validationErrors } from 'store/slices/validationSlice';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { REQUIRED_EMAIL_OR_NUMBER } from '../validationSchema';

const ContactPerson = () => {
  const dispatch = useDispatch();

  const errors = useSelector(validationErrors);

  const contactPersonInfo: ContactPersonData = useSelector(
    selectedContactPersonData
  );

  const [selectedOption, setSelectedOption] = useState<boolean>(
    contactPersonInfo.equalToCustomer
  );

  const [userData, setUserData] =
    useState<ContactPersonData>(contactPersonInfo);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email' || name === 'phone_number') {
      setUserData({
        ...userData,
        [name]: value.replace(/\s+/g, '')
      });

      dispatch(
        setContactPersonData({
          ...contactPersonInfo,
          [name]: value.replace(/\s+/g, '')
        })
      );
    } else {
      setUserData({
        ...userData,
        [name]: value
      });

      dispatch(
        setContactPersonData({
          ...contactPersonInfo,
          [name]: value
        })
      );
    }
  };

  const onTitleChange = (event: string) => {
    setUserData({ ...contactPersonInfo, title: event });

    dispatch(setContactPersonData({ ...contactPersonInfo, title: event }));
  };

  const onOwnerChange = (event: RadioChangeEvent) => {
    setSelectedOption(event.target.value);

    dispatch(
      setContactPersonData({
        ...contactPersonInfo,
        equalToCustomer: event.target.value
      })
    );
  };

  return (
    <div className={style.contact_person_container}>
      <Radio.Group
        value={selectedOption}
        onChange={onOwnerChange}
        className="margin_bottom_l"
      >
        <Row justify={'start'} gutter={[0, 24]} className={style.radio_wrapper}>
          <Col span={24} xl={6}>
            <Radio value={true}>Auftraggeber</Radio>
          </Col>
          <Col span={24} xl={12}>
            <Radio value={false}>Sonstige (bitte angeben)</Radio>
          </Col>
        </Row>
      </Radio.Group>

      {((!contactPersonInfo.equalToCustomer &&
        errors?.contactPersonData?.email &&
        userData.email === '' &&
        userData.phone_number === '') ||
        (!contactPersonInfo.equalToCustomer &&
          errors?.contactPersonData?.phone_number &&
          userData.email === '' &&
          userData.phone_number === '')) && (
        <ErrorMessage errorMessage={REQUIRED_EMAIL_OR_NUMBER}></ErrorMessage>
      )}

      {!selectedOption && (
        <>
          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={8} md={6}>
              <FloatingLabelSelect
                label="Anrede*"
                value={userData.title}
                options={[
                  { label: 'Herr', value: 'Herr' },
                  { label: 'Frau', value: 'Frau' }
                ]}
                onChangeHandler={onTitleChange}
                errorStatus={
                  errors?.contactPersonData?.title && userData.title === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.contactPersonData?.title && userData.title === ''
                    ? errors?.contactPersonData?.title
                    : undefined
                }
              />
            </Col>
            <Col span={16} md={9}>
              <FloatingLabelInput
                label="Vorname*"
                value={userData.first_name}
                onChangeHandler={onChangeHandler}
                name="first_name"
                errorStatus={
                  errors?.contactPersonData?.first_name &&
                  userData.first_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.contactPersonData?.first_name &&
                  userData.first_name === ''
                    ? errors?.contactPersonData?.first_name
                    : null
                }
              />
            </Col>
            <Col span={24} md={9}>
              <FloatingLabelInput
                label="Nachname*"
                value={userData.last_name}
                onChangeHandler={onChangeHandler}
                name="last_name"
                errorStatus={
                  errors?.contactPersonData?.last_name &&
                  userData.last_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.contactPersonData?.last_name &&
                  userData.last_name === ''
                    ? errors?.contactPersonData?.last_name
                    : null
                }
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="E-Mail*"
                value={userData.email}
                onChangeHandler={onChangeHandler}
                name="email"
                errorStatus={
                  errors?.contactPersonData?.email &&
                  !contactPersonInfo?.phone_number
                    ? 'error'
                    : errors?.contactPersonData?.email &&
                        errors?.contactPersonData?.phone_number
                      ? 'error'
                      : errors?.contactPersonData?.email
                        ? 'error'
                        : ''
                }
                errorMessage={
                  errors?.contactPersonData?.email &&
                  !contactPersonInfo?.phone_number
                    ? errors?.contactPersonData?.email
                    : errors?.contactPersonData?.email &&
                        errors?.contactPersonData?.phone_number
                      ? errors?.contactPersonData?.email
                      : errors?.contactPersonData?.email
                        ? errors?.contactPersonData?.email
                        : ''
                }
              />
            </Col>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="Mobiltelefonnummer für Nachfragen*"
                value={userData.phone_number}
                onChangeHandler={onChangeHandler}
                name="phone_number"
                errorStatus={
                  errors?.contactPersonData?.phone_number &&
                  !contactPersonInfo?.email
                    ? 'error'
                    : errors?.contactPersonData?.phone_number &&
                        errors?.contactPersonData?.email
                      ? 'error'
                      : errors?.contactPersonData?.phone_number
                        ? 'error'
                        : ''
                }
                errorMessage={
                  errors?.contactPersonData?.phone_number &&
                  !contactPersonInfo?.email
                    ? errors?.contactPersonData?.phone_number
                    : errors?.contactPersonData?.phone_number &&
                        errors?.contactPersonData?.email
                      ? errors?.contactPersonData?.phone_number
                      : errors?.contactPersonData?.phone_number
                        ? errors?.contactPersonData?.phone_number
                        : ''
                }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ContactPerson;
