import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generateNewSessionId } from 'store/slices/arSessionSlice';
import { AppDispatch, RootState } from 'store/store';
import { ARGenerateSessionRequestData } from 'types/bundle';

const useGenerateNewSessionIdData = () => {
  const dispatch: AppDispatch = useDispatch();

  const generateNewSessionIdStatus = useSelector(
    (state: RootState) => state.arSession.generateSessionStatus
  );

  const generateNewSessionIdError = useSelector(
    (state: RootState) => state.arSession.generateSessionError
  );

  const handleGenerateNewSessionIdAction = useCallback(
    (generateSessionData: ARGenerateSessionRequestData) => {
      return dispatch(generateNewSessionId(generateSessionData));
    },
    [dispatch]
  );

  return {
    generateNewSessionIdStatus,
    generateNewSessionIdError,
    handleGenerateNewSessionIdAction
  };
};

export default useGenerateNewSessionIdData;
